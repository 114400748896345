import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const CirclePlusIcon: FC<IconProps & { stroke?: string }> = (props) => {
  const { onClick, className, stroke = '#C6A97D' } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="currentColor" style={{ stroke: stroke }} />
      <path d="M23.0711 15.9998L8.92893 15.9998" stroke="#3E2234" strokeWidth="2" />
      <path d="M16 8.92893L16 23.0711" stroke="#3E2234" strokeWidth="2" />
    </svg>
  );
};

export default CirclePlusIcon;
